.workspaceHeader {
    background-color: var(--background-block);
    padding: 8px 24px;
    margin-bottom: 16px;
    border-radius: 5px;
}

.workspaceTitle {
    font-size: 1.7rem ;
    font-weight: 600;
}
.workspaceSingle{
    padding: 0px 48px 0px 48px;
}
.workspaceCardBody{
    padding: 16px;
}
// mobile version styles
@media (max-width: 768px) {
    .workspaceTitle {
        font-size: 18px;
        font-weight: 600;
    }
    .workspaceSingle{
        padding: 0px 8px 0px 8px;
    }
    .workspaceCardBody{
        padding: 0px 8px 0px 8px;
    }
}
.asLink{
    color: #273748;
    font-weight: bold;
    text-decoration: underline;
    &:hover{
        cursor: pointer;
        color: blue;
    }
}
.openNewWorkspace{
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        color:lightgray;
    }
}