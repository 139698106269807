.agileLinkActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px 5px 0px;
}
.agileViewBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.agileModal{
  padding: 32px 32px 48px 32px;
}
.hamburgerBtn {
  display: none;
}
.projectButtons{
  display: flex;
}
@media (max-width: 1022px) {
  .hamburgerBtn {
    display: block;
  }

  .projectButtons, .agileLinkActive, .agileViewBtn {
    display: none;;
  }
}

.activeActionBtn {
  color: #fff;
  background-color: #273748;

  &:hover {
    color: #fff;
    background-color: #355578;
  }
}

.projectCardBody {
  padding: 30px 16px 60px 16px;
}
@media screen and (max-width: 768px) {
  .projectCardBody{
    padding: 16px 8px 32px 8px;
  }
  }
