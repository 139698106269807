.buttonBase {
  font-size: 1rem;
  color: var(--text-color) !important;
  padding: 8px 16px;
  line-height: 1.5;
  border-radius: 0.2rem !important;
  margin-right: 0.5rem;
  border: none !important;
  cursor: pointer;
}

.selectWorkspace {
  @extend .buttonBase;
  background-color: var(--project-background) !important;

  &:hover {
    background-color: #dde1e7 !important;
    color: #344050 !important;
  }
}

.activeDropdown {
  @extend .buttonBase;
  background-color: #d0e1f4 !important;
}

.dropdown-toggle::after {
  display: inline-block !important;
  margin-left: 0.255em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.active {
  color: #fff !important;
  background-color: #273748 !important;
  &:hover {
    color: #fff !important;
    background-color: #355578 !important;
  }
}
.activeButton {
  @extend .active;
  border-radius: 0.2rem !important;
}
.lastItem {
  color: #fff;
  background-color: #273748;
}

.customDropdownMenu {
  max-height: 500px;
  overflow-y: auto;
}

.customDropdownMenu::-webkit-scrollbar {
  width: 10px; /* ширина скролбару */
}

.customDropdownMenu::-webkit-scrollbar-track {
  background: white; /* колір фону скролбару */
}

.customDropdownMenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* колір самого скролбару */
  border-radius: 5px; /* радіус кутів скролбару */
}