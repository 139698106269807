.free {
  background-color: lightgray;
  border: 2px solid gray;
  width: 70px;
  height: 20px;
  top: 10px;
  border-radius: 25px;
  position: absolute;
}

.fixed {
  background-color: pink;
  border: 2px solid red;
  width: 70px;
  height: 20px;
  top: 10px;
  border-radius: 25px;
  position: absolute;
}

.icon-span {
  position: absolute;
  top: 40%;
  right: 3%;
  width: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.closeDiv {
  position: absolute;
  top: 60%;
  width: 25px;
  height: 25px;
  background-color: red;
  margin-top: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

div.task-item {
  background-color: var(--background-block);
  cursor: pointer;
}
div.task-item:hover {
  background-color: #e9e9eb;
  cursor: pointer;
}
div.overdue-task {
  background-color: #fff0ea;
  cursor: pointer;
}
div.overdue-task:hover {
  background-color: #ffe6db;
  cursor: pointer;
}
div.actioned-task {
  /* background-color: #f1ffe9; */
  background-color: #F2F8FF;
}
div.actioned-task:hover {
  /* background-color: #e8ffd9; */
  background-color: #EBF2FB;
}
div.task-hr {
  border-top: 1px solid lightgray;
}

.fc .fc-toolbar-title {
  font-size: 1.45em !important;
  margin: 0;
}

.fc .fc-button {
  padding: 0.2em 0.4em !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.7em !important;
}

.multi-value-remove {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0px 0px 2px;
}

.circle {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.multi-value-remove:hover {
  color: darkred;
}
.clear-indicator {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ------------------------------------------------Task list--------------------------------------------------- */
.nameHeader {
  width: 650px;
}
.taskWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 1; */
}
.tagsHeader {
  width: 250px;
}
.tagsBlock {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  width: 250px;
}
.tagBadge {
  width: 120px;
}
.datePriorityBlock {
  display: flex;
}
.dateHeader {
  display: flex;
  align-items: center;
  width: 90px;
  font-size: 14px;
  text-align: center;
}
.dateValue {
  display: flex;
  align-items: center;
  width: 90px;
  font-size: 13px;
}
.priorityHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 50px;
}
.priority {
  display: flex;
  align-items: center;
  width: 50px;
  font-size: 14px;
}
.hamburgerDropdownBlock {
  display: flex;
  align-items: center;
  width: 56px;
  /* height: 60px; */
  cursor: default;
}

.rightBlock {
  display: flex;
  align-items: center;
}
.teamMemberHeader,
.teamMember {
  width: 160px;
}
.teamMember {
  font-size: 14px;
  padding-left: 2px;
}
.subtaskRightBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 606px;
}
.subtaskTagsBlock {
  display: flex;
  width: 250px;
}
.taskWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.taskName {
  font-size: 16px;
  min-width: 250px;
  margin-right: 4px;
}
.subtaskName {
  font-size: 16px;
}
.subtaskWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 24px;
}
.subtaskName {
  display: flex;
  min-width: 210px;
  width: fit-content;
  padding-right: 8px;
}

@media (max-width: 1131px) {
  .subtaskName {
    min-width: 160px;
  }
}
@media (max-width: 1100px) {
  .tagsHeader,
  .teamMemberHeader,
  .priorityHeader,
  .dateHeader {
    display: none !important;
  }
  .dateFrom,
  .dateTo {
    width: 49%;
  }
  .dateDeadline {
    width: 49%;
  }
  .taskWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .rightBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    width: 100%;
  }

  .tagsBlock {
    justify-content: flex-start;
    align-items: center;
    /* flex-wrap: wrap; */
    width: 300px;
  }
  .tagBadge {
    margin-top: 0px;
  }

  .subtaskTagsBlock {
    display: flex;
    justify-content: flex-end;
    /* width: 250px; */
    /* min-width: fit-content; */
  }
}
@media (max-width: 1080px) {
  .subtaskWrapper {
    flex-wrap: wrap;
  }
  .subtaskName {
    min-width: 100%;
  }
  .subtaskTagsBlock {
    justify-content: flex-start;
    margin-left: 8px;
  }
}
@media (max-width: 975.2px) {
  .subtaskRightBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: auto;
  }
  .subtaskActionerDate {
    margin-top: 8px;
  }
}
@media (max-width: 768px) {
  .taskName {
    min-width: 220px;
    margin-right: 4px;
    font-size: 14px;
  }
  .subtaskName {
    min-width: 100%;
    font-size: 14px;
  }
  .datePriorityBlock {
    display: flex;
    align-items: center;
  }
  .dateValue {
    min-width: 100px;
  }
  .priority {
    width: 100%;
  }
  .tagsBlock {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}

/* ----------------Messages----------------------------------------------------------------------------------- */
.messageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.selectBlock {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
.paperIcon,
.sendIcon {
  width: 36px !important;
}
.paperIcon {
  margin-right: 8px;
}
.sendIcon {
  margin-left: 8px;
}
.selectSend,
.selectVisible {
  width: 100%;
}
.selectSend {
  margin-right: 4px;
}
.selectVisible {
  margin-left: 4px;
}
.newAttachment {
  display: none !important;
}
.attachmentFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.attachedFile:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .messageWrapper {
    display: flex;
    flex-direction: column;
  }
  .paperIcon,
  .sendIcon {
    width: 100% !important;
  }
  .paperIcon,
  .selectSend {
    margin-right: 0px;
  }
  .sendIcon,
  .selectVisible {
    margin-left: 0px;
  }
  .newAttachment {
    display: block;
  }
  .attachmentFields {
    display: flex;
    flex-direction: column;
  }
  .selectBlock {
    display: flex;
    flex-direction: column;
  }
}

/* ---------------------------------Mobile version for form ---------------------------------- */
.selectsBlock {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.selectItem {
  width: 50%;
}
.datesBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dateFrom,
.dateTo,
.dateDeadline {
  width: 32%;
}

.fieldItem,
.workspaceItem,
.dateAdded {
  width: calc(50% - 8px);
}
.dateAddede {
  margin-right: 16px;
}
.statusBlockTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .selectsBlock {
    display: flex;
    flex-direction: column;
  }
  .selectItem {
    width: 100%;
  }}
@media screen and (max-width: 768px) {
  .datesBlock,
  .statusBlockTop {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .dateFrom,
  .dateTo,
  .dateDeadline {
    width: 100%;
  }

  .fieldItem,
  .workspaceItem,
  .dateAdded {
    width: 100%;
  }

  .workspaceItem, .dateAdded {
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

/* TaskKanban.js */
.ant-card{
  min-height: 120px!important;
}
.ant-card-head{
  color: white!important;
  background-color: #3174ad!important;
  min-height: 42px!important;
  padding-left: 16px!important;
  padding-right: 16px!important;
}
.kanban-current .ant-card-head{
  color: white!important;
  background-color: rgb(212, 148, 29)!important;
  min-height: 42px!important;
  padding-left: 16px!important;
  padding-right: 16px!important;
}
.kanban-testing .ant-card-head{
  color: white!important;
  background-color: green!important;
  min-height: 42px!important;
  padding-left: 16px!important;
  padding-right: 16px!important;
}
.ant-card-body{
  padding: 16px!important;
  background-color: #d0e1f4!important;
  min-height: 80px!important;
}
.task-ant-card .ant-card-body{
  background-color: white!important;
  border-radius: 8px;
  padding: 8px;
}

.react-grid-layout{
  width: 100%!important;
}

.react-resizable-handle {
  background-image: none!important;
}
